import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide44/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide44/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide44/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide44/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide44/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo implementar la ética en el servicio al cliente de una empresa
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La ética en el servicio al cliente es un pilar fundamental para cualquier
      empresa. Se refiere a los principios morales que rigen la conducta de una
      compañía hacia los clientes, potenciales clientes y exclientes.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Además, una reputación como empresa ética se convierte en un activo valioso
    para atraer talento. Las compañías con una reputación moral deficiente a
    menudo deben ofrecer salarios más altos para incentivar a las personas a
    trabajar para ellas.
    <br />
    <br />
    La ética al cliente es crucial en la gestión de reseñas negativas, ya que un
    código moral claro sirve como base de comportamiento y referencia,
    contribuyendo a disminuir críticas desfavorables.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "¿Qué hacer en situaciones de rechazo?",
      },
      {
        key: 2,
        text: "Asistencia de ventas.",
      },
      {
        key: 3,
        text: "Establecer política de atención.",
      },
      {
        key: 4,
        text: "Crear estándares de atención al cliente.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
